import React from 'react';

const Layout = ({ main, header, isAuth }) => {

    return (
        <div className="py-32 px-6 min-h-screen max-w-screen-2xl mx-auto lg:flex-row flex flex-col">
            <div className={`${isAuth ? 'lg:w-1/3' : 'hidden'}`}>
                {header}
            </div>
            <main className={`${isAuth ? 'lg:w-2/3 lg:pl-12 lg:pr-[5vw]' : 'flex w-full align-center justify-center'}`}>{main}</main>
        </div>
    );
};

export default Layout;
