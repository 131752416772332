import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

function Login({ setIsAuth, isAuth }) {
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	function handlePasswordChange(event) {
		setPassword(event.target.value);
	}

	function handleSubmit(event) {
		event.preventDefault();
		// validate login password here
		if (password === process.env.REACT_APP_PASSWORD) {
			localStorage.setItem('isLoggedIn', 'true');
			setIsAuth(localStorage.getItem('isLoggedIn') === 'true');
		} else {
			setError('So long, and thanks for all the fish.');
		}
	}

	return (
		<div className={isAuth ? `hidden` : `block overflow-hidden flex flex-col items-center justify-center`}>
			<form onSubmit={handleSubmit} className="flex flex-col items-center">
				<Fade delay={500} bottom distance="2rem">
					<label className="text-3xl m-1 flex flex-col items-center">
						Hello, who are you?
					</label>
				</Fade>
				<Fade delay={1500} bottom distance="2rem">
					<input 
						autoComplete="password"
						className="text-center text-3xl px-6 py-4 mt-6 mb-2 border border-slate-800 bg-black text-white"
						type="password" value={password} onChange={handlePasswordChange} />
					<Fade bottom distance="1rem">
						{error && <p className="text-lg text-red-500">{error}</p>}
					</Fade>
				</Fade>
				<Fade delay={1600} bottom distance="2rem">
					<button className='mt-8 bg-transparent text-white text-md px-4 py-2 hover:bg-black bg-slate-600 border border-slate-600 rounded-md transition-colors duration-200 ease-in-out'>
						Login
					</button>
				</Fade>
			</form>
		</div>
	);
}

export default Login;