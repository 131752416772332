// import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Header from './components/Header';
import Projects from './components/Projects';
import Awards from './components/Awards';
import Profile from './components/Profile';
import Experience from './components/Experience';
import projectsJSON from './data/projects.json';
import Fade from 'react-reveal/Fade';
import Login from './components/Login';
import NotFound from './components/NotFound';

function App() {
  const navigate = useNavigate();
	const location = useLocation();
  const [isAuth, setIsAuth] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [projects] = useState(projectsJSON.sort((a, b) => {
    return Number(b.year) - Number(a.year);
  }));

  useEffect(() => {
		if (!isAuth && location.pathname !== '/login') {
		  navigate('/login');
		} else if (isAuth && location.pathname === '/login') {
			navigate('/');
		}
	}, [isAuth, location.pathname, navigate]);

  return (
    <Layout
      isAuth={isAuth}
      header={isAuth ? <Header isAuth={isAuth} /> : null}
      nav={<div className="bg-green-500 min-h-16">Nav</div>}
      main={
        <Routes>
          <Route path="/login" element={<Login setIsAuth={setIsAuth} isAuth={isAuth} />} />
          {
            isAuth &&
              <Route path="/" element={
                <div className="max-w-screen overflow-x-hidden pt-24 lg:p-0 min-h-16 flex flex-col">
                  <Profile />
                  <Experience />
                  <Projects projects={projects} />
                  <Awards />
                  <section className="pt-16 pb-8">
                    <Fade right distance="3rem">
                      <h2 className="font-bold text-4xl pb-64">So long, and thanks for all the fish!</h2>
                    </Fade>
                    <small className="text-slate-400">Copyright &copy; 2025</small>
                  </section>
                </div>
              }/>
          }
          {
            !isAuth ? <Route path="*" element={<Login setIsAuth={setIsAuth} isAuth={isAuth} />} /> : <Route path="*" element={<NotFound />} />
          }
        </Routes>
      }
    />
  );
}

export default App;
