import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import React from 'react';

const Icon = ({ variant, className }) => {
    return (
        <FontAwesomeIcon 
            className={`${(className) ? ' ' + className : ''}`}
            icon={
                // switch for various fontawsome icons
                (() => {
                    switch (variant) {
                        case 'envelope':
                            return faEnvelope;
                        case 'linkedin':
                            return faLinkedinIn;
                        case 'calendar':
                            return faCalendar;
                        default:
                            return faEnvelope;
                    }
                })()
            }
        />
    )
}

export default Icon;