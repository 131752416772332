// react component for projects
import Project from "./Project";
import { Fade } from "react-reveal";

const Projects = ({ projects }) => {
    return (
        <section className="pt-16 pb-32">
            <Fade right distance="3rem" cascade>
                <h2 className="font-bold text-4xl">Projects</h2>
            </Fade>
            <ul>
            {projects.map((project) => {
                return (
                project.active &&
                    <Project key={`${project.client} ${project.year}`} data={project} />
                );
            })}
            </ul>
        </section>
    )
};

export default Projects;
