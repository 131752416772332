import React from 'react';
// import Icon from './Icon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Fade from 'react-reveal/Fade';
// import { animDelay } from './../data/globals.js';

function Project({data}) {
    return (        
        <li className="flex flex-col pt-16 pb-32 border-b-2 border-slate-800">
            <Fade>
                {/* <span className="pb-6 text-2xl text-white text-right">
                    <span className="font-bold">{data.year}<Icon variant={"calendar"} className="fa-sm pl-2" /></span>
                </span> */}
                <div className="flex flex-col md:flex-row md:justify-between">
                    <h3 className="font-bold text-lg md:text-xl pb-2 md:pb-4 flex flex-row">
                        {data.agency} 
                        {data.client && <span className="mx-4 font-light">|</span>} 
                        <span className="text-slate-300 font-light">{data.client}</span>
                    </h3>
                    <span className="font-bold text-xl pb-4">{data.role}</span>
                </div>
            </Fade>
            <Fade>
                <div className="pb-12">
                    <LazyLoadImage
                        width={"100%"}
                        effect="opacity"
                        alt={data.client}
                        src={process.env.PUBLIC_URL + `/assets/${data.hero}`} />
                </div>
            </Fade>
            <Fade>
                <p className="text-slate-300 pb-8">{data.description}</p>
            </Fade>
            <Fade>
                <p className="text-slate-300 text-md"><span className="text-slate-200 font-bold">Stack:</span> {data.tech}</p>
            </Fade>
        </li>
    );
}

export default Project;
