import { Fade } from "react-reveal";
// import { animDelay } from "./../data/globals.js";

const Experience = () => {
    return (
          <div className="pt-24 grid grid-cols-1 md:grid-cols-2 gap-8 pb-40 border-b-2 border-gray-900">
            <div>
                <Fade right distance="3rem">
                    <h4 className="font-bold text-xl">Studios / Agencies</h4>
                    <p className="pb-4 text-slate-200">I've worked with:</p>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li>DVXD</li>
                        <li>Playground Inc</li>
                        <li>Jam3</li>
                        <li>Array Of Stars</li>
                        <li>Entertainment One</li>
                        <li>Secret Location</li>
                        <li>Tribal DDB</li>
                        <li>Grip Limited</li>
                        <li>TVOntario</li>
                        <li>One Pixel Off</li>
                    </ul>
                </Fade>
                <Fade right distance="3rem">
                    <h4 className="pt-12 font-bold text-xl">Clients</h4>
                    <p className="pb-4 text-slate-200">projects I've worked on:</p>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li>Bank of Montreal</li>
                        <li>Disney</li>
                        <li>Entertainment One</li>
                        <li>Guardian UK</li>
                        <li>History Channel</li>
                        <li>Indigo</li>
                        <li>Honda</li>
                        <li>MyDoh</li>
                        <li>Myodetox</li>
                        <li>National Film Board of Canada</li>
                        <li>Qaggiavuut</li>
                        <li>RBC Future Makers</li>
                        <li>Samsung</li>
                        <li>Volkswagen</li>
                        <li>Wander The Resort</li>
                        <li>Willow</li>
                        <li className="font-bold text-slate-200">And many more...</li>
                    </ul>
                </Fade>
            </div>
            <div className="pr-2">
                <Fade right distance="3rem">
                    <h4 className="font-bold text-xl">Technical Skills & Experience</h4>
                </Fade>
                <Fade right distance="3rem" cascade>
                    <ul className="text-slate-400">
                        <li className="text-slate-200 pt-6 font-bold">Foundations</li>
                        <li>OOP and Functional design patterns</li>
                        <li className="text-slate-200 pt-6 font-bold">Languages</li>
                        <li>JavaScript (ES6), Typescript, PHP, SQL, Python</li>
                        <li className="text-slate-200 pt-6 font-bold">Frameworks</li>
                        <li>React, React Native, Next, Vue, etc</li>
                        <li className="text-slate-200 pt-6 font-bold">Backend</li>
                        <li>Node, Express</li>
                        <li>Relational and non-relational database design</li>
                        <li>MySQL, PostgreSQL, MongoDB</li>
                        <li className="text-slate-200 pt-6 font-bold">Cloud & Deployment</li>
                        <li>AWS, GCP, Netlify, Docker</li>
                        <li className="text-slate-200 pt-6 font-bold">APIs</li>
                        <li>RESTful, GraphQL</li>
                        <li className="text-slate-200 pt-6 font-bold">Tooling & Testing</li>
                        <li>Git, package managers, test & audit suites, CI/CD pipelines, Copilot, etc</li>
                        <li className="text-slate-200 pt-6 font-bold">Web</li>
                        <li>Semantic, Progressively Enhanced, Accessibility Compliant</li>
                        <li className="text-slate-200 pt-6 font-bold">Creative Web Technologies</li>
                        <li>ThreeJS, GLSL Shaders, D3JS, Websockets</li>
                        <li className="text-slate-200 pt-6 font-bold">Web Animatics</li>
                        <li>CSS & SVG Animatics, HTML Canvas, Greensock</li>
                        <li className="text-slate-200 pt-6 font-bold">Mobile Games</li>
                        <li>Entity Component Systems (ECS), Phaser, Pixi</li>
                        <li className="text-slate-200 pt-6 font-bold">eCommerce</li>
                        <li>Shopify custom themes & headless</li>
                        <li>Wordpress & Gutenberg</li>
                        <li className="text-slate-200 pt-6 font-bold">Machine Learning</li>
                        <li>OpenAI API, Vertex AI API, Advanced Prompting</li>
                    </ul>
                </Fade>
            </div>
        </div>
    )
}

export default Experience;