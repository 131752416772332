import Fade from "react-reveal/Fade";

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center">
            <Fade right distance="2rem">
                <h1 className="text-3xl font-bold">404 Page Not Found</h1>
            </Fade>
        </div>
    )
};

export default NotFound;