import Fade from 'react-reveal/Fade';

const Profile = () => {
    return (
        <section>
            <Fade right distance="3rem">
                <p className="text-lg pb-8">
                    Senior Software Developer with over a decade experience architecting client-focused solutions. Expertise in full-stack web development using modern JavaScript frameworks and designing secure, scalable backends with a wide variety of technology stacks, APIs and microservices. Committed to building performant, accessible, visually engaging presentation layers and design systems.
                </p>
                <p className="text-lg">
                    Above all, I am a creative problem-solver, adaptable and ethically minded.
                </p>
            </Fade>
        </section>
    )
};

export default Profile;