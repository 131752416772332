import React from "react";
import Icon from "./Icon";
import Fade from  "react-reveal/Fade";
import { animDelay } from "./../data/globals.js";

const Header = ({ isAuth }) => {
    return (
        <header className="lg:pt-32 w-full lg:fixed lg:top-0">
            <div className={`${isAuth ? 'h-[80vh]' : 'pb-12'} lg:h-auto`}>
                <Fade delay={animDelay * 1} right distance="1rem">
                    <h1 className="text-slate-100 font-bold text-5xl pb-2">Steph Coleman</h1>
                </Fade>
                <Fade delay={animDelay * 2} right distance="1rem">
                    <h2 className="text-slate-300 font-medium text-xl pb-2">Senior Software Developer</h2>
                </Fade>
                <Fade delay={animDelay * 3} right distance="1rem">
                    <h3 className="text-slate-500 font-light text-md pb-2">Toronto, Canada.</h3>
                </Fade>
                <Fade delay={animDelay * 4} right distance="1rem" cascade>
                    <ul className="pt-16 flex flex-row">
                        <li>
                            <a href="mailto:thestephencoleman@gmail.com">
                                <Icon variant={"envelope"} className="text-slate-400 fa-xl pr-4" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/stephcoleman/">
                                <Icon variant={"linkedin"} className="text-slate-400 fa-xl pr-4" />
                            </a>
                        </li>
                    </ul>
                </Fade>
            </div>
        </header>
    );
};

export default Header;