import Fade from "react-reveal/Fade";

const Awards = () => {
    return (
        <section className="pb-32 border-b-2 border-gray-900">
            <Fade right distance="3rem" cascade>
                <h4 className="text-4xl pt-12 font-bold">Awards</h4>
                <p className="text-slate-200 pb-4">assisted in winning:</p>
            </Fade>
            <Fade right distance="3rem" cascade>
                <ul className="text-slate-400">
                <li><a className="hover:underline" href="https://atomicawards.strategyonline.ca/winners/winner/2019/?e=71710&w=The+BMO200+fountain+" target="_blank" rel="noreferrer">2019 - Bronze Best Experiential Engagement</a></li>
                <li><a className="hover:underline" href="https://www.iemmys.tv/awards_nominees.aspx" target="_blank" rel="noreferrer">2017 - Emmy Nomination for KIDS: DIGITAL</a></li>
                <li><a className="hover:underline" href="http://www.academy.ca/Canadian-Screen-Awards/2016-Nominees-Winners/Digital-Media" target="_blank" rel="noreferrer">2016 - Canadian Screen Awards Best Cross-Platform</a></li>
                <li><a className="hover:underline" href="http://www.thefwa.com/site/dino-hunt-canada-interactiv" target="_blank" rel="noreferrer">2015 - January 29, FWA Site of the Day</a></li>
                <li><a className="hover:underline" href="http://www.awwwards.com/web-design-awards/seven-digital-deadly-sins" target="_blank" rel="noreferrer">2014 - June 30, AWWWARDS</a></li>
                <li><a className="hover:underline" href="http://www.thefwa.com/site/seven-digital-deadly-sins?p=jam3" target="_blank" rel="noreferrer">2014 - July 28, FWA Site of the Day</a></li>
                <li><a className="hover:underline" href="http://www.thefwa.com/mobile/samsung-bring-it-home" target="_blank" rel="noreferrer">2014 - March 18, FWA MOBILE OF THE DAY</a></li>
                </ul>
            </Fade>
        </section>
    )
};

export default Awards;
